import titleImg from '../resources/images/Title.png'

const Header = () => {
  return (
    <header>
        <img className="headerImg" src={titleImg}></img>
    </header>
  )
}

export default Header